:root {
    --contexify-activeItem-color: #333;
    --contexify-activeItem-bgColor: #fafafa;
}

:focus-visible {
    outline: none
}

.contexify {
    background-color: #fff;
    font-family: "SFProDisplay";
    border-radius: 4px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1)
}

.MuiPickersCalendarHeader-root svg {
    color: #999
}
